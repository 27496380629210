export const ProjectData = [
  {
    id: 'me',
    title: 'Hello 👋',
    paragraph: 'I ́m a Front End Engineer / Creative Technologist. Hailing from the cold Scandinavia, I am now embarking on a new chapter in Brooklyn, New York ready to explore new opportunities.',
    paragraph2: 'My experience of 7 years spans across various environments - agencies, studios, start-ups and corporate. I ́ve had the privilege of working on numerous award-winning projects and specialise in all stages of development, from ideation and concepting through UX and interactions to writing high quality code.',
    cta: 'I also take photos.',
    url: 'https://www.nataliawojtkowska.co/',
  },
  {
    id: 'interland',
    title: 'Google Interland',
    paragraph: 'A game designed for children to learn about the dangers of being online. Free, interactive and browser-based it takes kids on an adventure through four worlds, each of them carrying an important lesson about security, cyberbulling, phishing and hacking.',
    tech: 'WebGL, Three.js, Blender, Lottie, Babel, Pug, GreenSock, Modernizr',
    role: 'Front End Engineer',
    url: 'https://www.14islands.com/work/interland',
    siteUrl: 'https://beinternetawesome.withgoogle.com/en_us/interland'
  },
  {
    id: 'jffd',
    title: 'Just Food For Dogs',
    paragraph: 'A subscription service for one of the biggest fresh pet food providers in the US. A landing page with an entire customer journey, from account management to checkout, creating a personalised food plan for dog owners, and payment integration. ',
    tech: 'Vue.js, Stripe, Modernizr, Lottie, Heroku',
    role: 'Front End Engineer',
    url: 'https://www.14islands.com/work/justfoodfordogs',
  },
  {
    id: 'adidas',
    title: 'Adias EQT',
    paragraph: 'A campaign website with a WebGL 360° video experience, where the user could interact directly with the film and explore the products. Built for the launch of Adidas Original EQT range',
    tech: 'Modernizr, Three.js, GreenSock, Pug',
    role: 'Front End Engineer',
    url: 'https://www.northkingdom.com/case/adidas-eqt',
  },
  {
    id: 'loomis',
    title: 'Loomis Pay',
    paragraph: 'A brand new marketing site for a digital-first payment service that helps merchants, restaurants, and shops run and grow their businesses.',
    tech: 'React.js, Next.js, Typescript, SASS, Prismic',
    role: 'Front End Engineer',
    siteUrl: 'https://loomispay.com/en',
  },
  {
    id: 'neuro',
    title: 'Neuroforbundet',
    paragraph: 'A web based campaign for a Swedish charity organisation. Designed specifically for passengers on the train, as an informative and fun way to pass time. A game that illustrates the struggles of living with Parkinsons disease. Integrated with Swish - a Swedish payment app to raise money for the cause.',
    tech: 'GreenSock, Lottie, Pug, Stylus, Heroku, Stripe',
    role: 'Front End Engineer',
    url: 'https://www.14islands.com/work/neuro',
    siteUrl: 'https://vardagskampen.se/'
  },
  {
    id: 'brooklyn',
    title: 'Brooklyn Vignettes',
    paragraph: 'A website for a Radio Free Brooklyn podcast. The identity, design, typography, and development done entirely by me.',
    tech: 'Vue.js, Nuxt, Contentful, Netlify',
    role: 'UX & Visual Design, Brand Design, Front End Engineer',
    siteUrl: 'https://www.thebrooklynvignettes.com/',
  },
  {
    id: 'xmas',
    title: 'Christmas VR card',
    paragraph: 'A company Holiday Greetings VR "card" to send to friends and clients, meant to be viewed on Google Cardboard. Concept and development by me, as well as many of the 3D models were sculpted in Google Blocks.',
    tech: 'Google Blocks, Blender, A-Frame',
    role: 'Creative Direction, 3D Artist, Front End Engineer',
    siteUrl: 'https://2017.14islands.com/',
  },
  {
    id: 'balloon',
    title: 'Hot air balloon ride',
    paragraph: 'A fun little project to learn Three.js and VR. A hot air balloon ride around a floating island, where user can fly higher by using hand controllers.',
    tech: 'Three.js, Blender',
    role: 'Creative Direction, Visual Designer, Front End Engineer',
    url: 'https://v2.14islands.com/blog/2017/05/17/engaging-vr-experiences-on-the-web/',
  },
  {
    id: 'flamingo',
    title: 'Flamingo Sunset',
    paragraph: 'A simple A-Frame VR experience built for a chapter of Complete Guide to VR & 360 Degree photography. I created the experience and wrote a tutorial on how to get started with web VR',
    tech: 'A-Frame, Glitch, Blender',
    role: 'Front End Engineer',
    cta: 'Check out the book',
    url: 'https://www.amazon.com/Complete-Guide-360-Degree-photography/dp/1781575398',
    siteUrl: 'https://flamingo-sunset.glitch.me/',
  },
  {
    id: 'marge',
    title: 'WebAR experiments',
    paragraph: 'I also love playing around with web AR and have made side projects like AR tamagotchi pet dog, an AR weather app protoype or just funny little scenes like Marge yelling from the top of the building',
    tech: '8th Wall / r3f + ngrok',
  },
]; 